//
.public-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
.public-main {
  background-color: #f7f7f7;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 100px;
}

.register-step {
  overflow: hidden;
  li {
    flex: 0 0 20%;
    max-width: 20%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      &::before {
        content: '';
        height: 10px;
        width: 100%;
        display: block;
        position: absolute;
        right: -50%;
        top: 10px;
        background-color: #ececec;
      }
    }

    &.active {
      .step-num {
        border: 1px solid var(--primary);
        background-color: var(--primary);
        color: var(--white);
      }
      &::before {
        background-color: var(--primary);
      }
    }

    .step-num {
      height: 30px;
      width: 30px;
      background-color: var(--white);
      border: 1px solid var(--secondary);
      color: var(--dark);
      border-radius: 100%;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      position: relative;
    }
    .step-title {
      color: var(--primary);
      font-size: 18px;
      margin-top: 5px;
      font-weight: 600;
    }
  }
}
